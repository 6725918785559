<template>
    <el-card>
        <div class="g-flex"  slot="header">
            <span>日志列表</span>
        </div>
        <el-form :inline="true" size="mini" :model="query">
            <el-form-item label="时间">
                <el-date-picker v-model="query.date_time" @change="getList()" type="datetimerange"
                                :default-time="['00:00:00', '23:59:59']" range-separator="至" start-placeholder="开始日期"
                                end-placeholder="结束日期">
                </el-date-picker>
            </el-form-item>

            <!-- <el-form-item label="用户角色">
                <el-select v-model="query.u_type" placeholder="查询">
                    <el-option label="全部" value="ALL"></el-option>
                    <el-option label="买家" value="1"></el-option>
                    <el-option label="商家" value="2"></el-option>
                    <el-option label="代理" value="3"></el-option>
                    <el-option label="管理员" value="4"></el-option>
                    <el-option label="超级管理员" value="100"></el-option>
                </el-select>
            </el-form-item> -->
                            
            
            <el-form-item label="标题">
                <el-input v-model="query.title" @keyup.enter.native="getList()" @clear="getList()" placeholder="请输入标题" clearable></el-input>
            </el-form-item>
            
            <el-form-item label="ip">
                <el-input v-model="query.ip" @keyup.enter.native="getList()" placeholder="请输入ip" clearable></el-input>
            </el-form-item>

            <el-form-item>
                <el-select slot="label" v-model="query.search_key" placeholder="查询">
                    <el-option label="手机号" value="mobile"></el-option>
                    <el-option label="用户名" value="user_name"></el-option>
                    <el-option label="用户id" value="user_id"></el-option>
                </el-select>
                <el-row>
                    <el-col :span="18">
                        <el-input v-model="query.search_val" @keyup.enter.native="getList()" @clear="getList()" placeholder="请输入查找内容" clearable></el-input>
                    </el-col>
                    <el-col :span="5" :offset="1">
                        <el-button type="primary" @click="getList()">查询</el-button>
                    </el-col>
                </el-row>
            </el-form-item>

        </el-form>
        <el-table stripe size="mini" v-loading="table.loading" :data="table.rows" border>
            <el-table-column label="用户id" width="140">
                <template slot-scope="scope">
                    <span>{{scope.row.user_id}}</span>
                    <span v-if="scope.row.u_type==1" class="g-red">(买家)</span>
                    <span v-else-if="scope.row.u_type==2" class="g-blue">(商家)</span>
                    <span v-else-if="scope.row.u_type==3" class="g-green">(代理)</span>
                     <span v-else-if="scope.row.u_type==4" class="g-grey">(管理员)</span>
                    <span v-else-if="scope.row.u_type==100" class="g-purple">(超级管理员)</span>
                </template>
            </el-table-column>
            
            <el-table-column prop="user_name" label="用户名" width="100" :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column prop="title" label="标题" width="140" :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column prop="msg" label="日志" :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column prop="ip"  label="ip" width="100" >
            </el-table-column>
            <el-table-column label="时间" width="140" :show-overflow-tooltip="true">
                <template slot-scope="scope">
                    <span>{{formatDate(scope.row.create_time, 'yyyy-MM-dd hh:mm')}}</span>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination :page-sizes="[15, 30, 60, 100]" :page-size="query.limit" :total="table.total" :current-page.sync="query.page" @current-change="getList(false)" @size-change="sizeChange" small hide-on-single-page background layout="total, sizes, prev, pager, next, jumper"></el-pagination>
    </el-card>
</template>

<script>
    import { apiGetLogList } from '@/utils/api.js'
  import { formatDate } from '@/utils/index.js'
    export default {
        name: "LogList",
        created(){
            this.getList()
        },
        data(){
            return {
                query: {
                    u_type: 'ALL',
                    title:'',
                    ip:'',
                    date_time: [],
                    search_key: 'user_name', search_val: '',
                    page: 1, limit: 15
                },
                table: {loading: false, total: 0, rows: []},
                form: false,
                formData: {},
            }
        },
        methods: {
          formatDate,
            //获取列表
            async getList(pageInit = true) {
                if (pageInit) this.query.page = 1
                this.table.loading = true
                const { success, data } = await apiGetLogList(this.query).catch(() => {this.table.loading = false })
                this.table.loading = true
                if(!success) return
                this.table.loading = false
                this.table.rows = data.rows
                this.table.total = data.total
            },
            //每页数量改变
            sizeChange(limit) {
                this.query.limit = limit
                this.getList()
            }
        }
    }
</script>